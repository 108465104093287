var Delighters = new (function () {
  var o = (this.dels = []),
    c = {
      attribute: "data-delighter",
      classNames: ["delighter", "started", "ended"],
      start: 0.25,
      end: 0.25,
      autoInit: !0,
    };
  function t() {
    document.addEventListener("scroll", u);
    for (
      var t = document.querySelectorAll("[" + c.attribute + "]"), e = 0;
      e < t.length;
      e++
    ) {
      var s = t[e],
        d = s.getAttribute(c.attribute, 2).split(";"),
        a = {};
      (a.start = c.start), (a.end = c.end);
      for (var n = 0; n < d.length; n++) {
        var i = d[n].split(":"),
          r = i[0],
          l = isNaN(+i[1]) ? i[1] : +i[1];
        r && (a[r] = void 0 === l || l);
      }
      (a.el = s),
        (a.id = o.length),
        o.push(a),
        s.classList.add(c.classNames[0]),
        a.debug && (s.style.outline = "solid red 4px");
    }
    u();
  }
  function u() {
    for (var t = window.innerHeight, e = 0; e < o.length; e++) {
      var s = o[e],
        d = s.el.getBoundingClientRect(),
        a = d.top / t,
        n = d.bottom / t;
      s.debug &&
        (0 <= a &&
          a <= 1 &&
          (s.startLine ||
            ((s.startLine = document.createElement("div")),
            document.body.appendChild(s.startLine),
            (s.startLine.style =
              "position:fixed;height:0;width:100%;border-bottom:dotted red 2px;top:" +
              100 * s.start +
              "vh"))),
        (n < s.end || 1 < a) &&
          s.startLine &&
          (s.startLine.parentNode.removeChild(s.startLine),
          delete s.startLine)),
        a < s.start && !s.started
          ? ((s.started = !0), s.el.classList.add(c.classNames[1]))
          : a > s.start &&
            s.started &&
            ((s.started = !1), s.el.classList.remove(c.classNames[1])),
        n < s.end && !s.ended
          ? ((s.ended = !0), s.el.classList.add(c.classNames[2]))
          : n > s.end &&
            s.ended &&
            ((s.ended = !1), s.el.classList.remove(c.classNames[2]));
    }
  }
  document.addEventListener("DOMContentLoaded", function () {
    c.autoInit && t();
  }),
    (this.init = t),
    (this.config = function (t) {
      for (var e in t) c[e] = t[e];
    });
})();

